import logo from './logo.svg';
import './App.css';
//import React, {useEffect} from 'react';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" /><br/>
        
        <p id='title'>الموقع تحت التطوير</p>
        <br/>
        <p id='sub-title'> جميع الحقوق محفوظة لشركة مداول © 2023</p>

      </header>
    </div>
  );
}

export default App;
